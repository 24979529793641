import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { showScanner } from "@store/actions/global";

interface Props {
    icon: string;
    iconSize?: number;
    navigation: any;
}

export class FooterFavButton extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
    }
    onPress() {
        store.dispatch(showScanner());
    }
    render() {
        return (
            <View style={{ paddingHorizontal: 10 }}>
                <CircleButton icon={this.props.icon} onPress={this.onPress}></CircleButton>
            </View>
        );
    }
}

const styles = StyleSheet.create({});
