import {SearchCardInterface} from "@custom-types/SearchCardInterface";
import {BoldText} from "@base/BoldText";
import {trimHelper} from "@utils/helpers/chat/chat.helper";
import RegularText from "@base/RegularText";
import React from "react";
import {TouchableHighlight, View} from "react-native";
import {SimpleLineIcons} from "@expo/vector-icons";
import store from "@store/index";
import {selectContact} from "@store/actions/chat.actions";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import {ChatService} from "@core/services/ChatService";

export class Contact implements SearchCardInterface {
    id: string;
    _id: string;
    client: any;
    name: string;

    static create(data) {
        if (data.clientId) {
            data.client = data.clientId;
        }

        if (data._id) {
            data.id = data._id;
        }

        return Object.assign(new Contact(), data);
    }

    getTitle() {
        return trimHelper(this.name);
    }

    getSubtitle() {
        return trimHelper(this.client.alias);
    }

    getControls(navigation) {
        return (
            <View
                style={{
                    position: "absolute",
                    right: 0,
                    top: 10,
                    flexDirection: "row"
                }}
            >
                <TouchableHighlight
                    style={{marginRight: 10}}
                    onPress={() => {
                        store.dispatch(selectContact(this));
                        navigation.navigate('ContactForm', {type: "edit"});
                    }}
                >
                    <SimpleLineIcons
                        style={{color: "white", margin: "auto"}}
                        size={20}
                        name={"pencil"}
                    />
                </TouchableHighlight>
                <TouchableHighlight onPress={async () => (await ChatService.getInstance()).removeContact(this._id)}>
                    <SimpleLineIcons
                        style={{color: "white", margin: "auto"}}
                        size={20}
                        name={"close"}
                    />
                </TouchableHighlight>
            </View>
        )
    }

    getClient() {
        return this.client;
    }

}