import React, { Component } from "react";
import { StyleSheet, TextStyle, KeyboardTypeOptions, TextInput, ViewStyle } from "react-native";
import { colors } from "@styles/globalStyles";

interface Props {
    style?: TextStyle | Array<ViewStyle>;
    fontSize?: number;
    color?: string;
    onChangeText: (value: any) => void;
    value?: any;
    editable?: boolean;
    align?: "auto" | "left" | "right" | "center" | "justify";
    autoCapitalize?: "none" | "sentences" | "words" | "characters";
    maxLength?: number;
    placeholder?: string;
    onFocus?: any;
    multiline?: boolean;
    secureTextEntry?: boolean;
    autoFocus?: boolean;
    fontWeight?: "light" | "regular" | "bold";
}

interface State {
    inlineStyles: TextStyle;
}

try {
    require("./input.css");
} catch { }

export default class RegularTextInput extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const defaultColor = props.editable === false ? "#838383" : colors.text;

        this.state = {
            inlineStyles: {
                fontSize: props.fontSize || 16,
                color: props.color || defaultColor,
                textAlign: props.align || "left",
                fontFamily: this.getFont(props.fontWeight)
            },
        };

        this.onBlur = this.onBlur.bind(this);
    }

    getFont(fontWeight: string) {
        const weight = fontWeight || "regular";
        return `Nunito${weight.charAt(0).toUpperCase()}${weight.slice(1)}`;
    }

    addStyles: () => TextStyle = () => ({ fontWeight: "normal" });

    onBlur = () => { };

    onFocus = () => { };

    onChangeText = this.props.onChangeText;

    setType: () => KeyboardTypeOptions = () => "default";

    setAutoCapitalize: () => "none" | "sentences" | "words" | "characters" = () => "none";

    render() {
        return (
            <TextInput
                {...this.props}
                value={this.props.value.toString()}
                style={[styles.defaultStyles, this.props.style, this.state.inlineStyles, this.addStyles()]}
                onChangeText={this.onChangeText}
                keyboardType={this.setType()}
                autoCapitalize={this.props.autoCapitalize || this.setAutoCapitalize()}
                onBlur={this.onBlur}
                editable={this.props.editable}
                onFocus={this.onFocus}
                placeholderTextColor={colors.grey}
                multiline={this.props.multiline}
                returnKeyType={"done"}
                secureTextEntry={this.props.secureTextEntry}
                autoFocus={this.props.autoFocus}
                accessibilityLabel="text-input"
            />
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        fontFamily: "NunitoRegular",
    },
});
