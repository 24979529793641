import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { Camera } from "expo-camera";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { connect } from "react-redux";
import * as Clipboard from "expo-clipboard";
import Container from "@base/Container";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";
import WalletConnectService from "@core/services/WalletConnectService";
import ScannerBase from "@screens/scanner/ScannerBase";
import WalletConnectV2Service from "@core/services/WalletConnectV2Service";

interface Props {
    navigation: NavigationType;
    selectedCurrency: string;
    route: any;
}

interface State {}

const { t } = i18n;

export class _WalletConnectScanner extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);

        this.executeBarCodeScanned = this.executeBarCodeScanned.bind(this);
        this.getWalletConnect = this.getWalletConnect.bind(this);
    }

    async getWalletConnect(data?: string) {
        store.dispatch(loading());
        await WalletConnectV2Service.getInstance().pairDapp(data);
        store.dispatch(ready());
    }

    executeBarCodeScanned(qrCode) {
        this.getWalletConnect(qrCode);
        this.goBack();
    }

    goBack = async () => {
        if (this.props.route?.params?.goBackTo) {
            this.props.navigation.goBack();
            this.props.navigation.navigate(this.props.route.params.goBackTo);
        } else {
            this.props.navigation.goBack();
        }
    };

    render() {
        return (
            <ScannerBase
                navigation={this.props.navigation}
                returnBarCodeScanned={this.executeBarCodeScanned}
                goBack={this.goBack}
            ></ScannerBase>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const WalletConnectScanner = connect(mapStateToProps, mapDispatchToProps)(_WalletConnectScanner);

export default WalletConnectScanner;

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: colors.background,
        padding: 0,
        paddingHorizontal: 0,
        paddingLeft: 0,
        paddingStart: 0,
        marginHorizontal: 0,
        marginLeft: 0,
        marginStart: 0,
    },
    scanner: {
        backgroundColor: "black",
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        height: "100%",
    },
    button: {
        position: "absolute",
        bottom: 50,
    },
    maskWrapper: {
        position: "absolute",
        width: 300,
        height: 300,
        justifyContent: "space-between",
    },
    maskTopLeft: {
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        width: 50,
        height: 50,
        borderTopLeftRadius: 10,
        borderLeftColor: "white",
        borderLeftWidth: 2,
        borderTopColor: "white",
        borderTopWidth: 2,
    },
    maskTopRight: {
        position: "relative",
        top: 0,
        bottom: 0,
        right: 0,
        width: 50,
        height: 50,
        borderTopRightRadius: 10,
        borderRightColor: "white",
        borderRightWidth: 2,
        borderTopColor: "white",
        borderTopWidth: 2,
    },
    maskBottomLeft: {
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        width: 50,
        height: 50,
        borderBottomLeftRadius: 10,
        borderLeftColor: "white",
        borderLeftWidth: 2,
        borderBottomColor: "white",
        borderBottomWidth: 2,
    },
    maskBottomRight: {
        position: "relative",
        top: 0,
        bottom: 0,
        right: 0,
        width: 50,
        height: 50,
        borderBottomRightRadius: 10,
        borderRightColor: "white",
        borderRightWidth: 2,
        borderBottomColor: "white",
        borderBottomWidth: 2,
    },
});
