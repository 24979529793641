import React, { Component } from "react";
import { StyleSheet,  ViewStyle } from "react-native";
import RegularText from "./RegularText";
import { colors, settings } from "@styles/globalStyles";
import GradientWrapper from "@components/wrapper/GradientWrapper";
import BoldText from "./BoldText";
import PressableBase from "./PressableBase";

interface Props {
    title: string;
    style?: ViewStyle;
    align?: "auto" | "left" | "right" | "center" | "justify";
    fontSize?: number;
    boldText?: boolean;
    color?: string;
    disableGradient?: boolean;
    border?: boolean;
    onPress: () => void;
}

interface State { }

export default class RadiusButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <PressableBase onPress={this.props.onPress} accessibilityLabel="radius-button">
                <GradientWrapper
                    disable={this.props.disableGradient}
                    style={[
                        styles.defaultStyles,
                        this.props.style,
                        this.props.border && { borderWidth: 1, borderStyle: "solid", borderColor: colors.secondary },
                    ]}
                >
                    {this.props.boldText ? (
                        <BoldText fontSize={this.props.fontSize || 16} color={colors.white} align="center">
                            {this.props.title}
                        </BoldText>
                    ) : (
                        <RegularText fontSize={this.props.fontSize || 16} color={colors.white} align="center">
                            {this.props.title}
                        </RegularText>
                    )}
                </GradientWrapper>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        padding: 10,
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderRadius: settings.mainButtonsRadius || 25,
        overflow: "hidden",
    },
});
