import React, { Component } from "react";
import { View, StyleSheet, Platform, BackHandler } from "react-native";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { WebView } from "react-native-webview";
import { IWalletConnectSession } from '@core/services/WalletConnectDataService';
import { IWalletConnectStore } from '@core/services/WalletConnectDataService';
import WalletConnectService from "@core/services/WalletConnectService";
import { trimHelper } from '@utils/helpers/chat/chat.helper';
import * as Clipboard from 'expo-clipboard';
import store from "@store/index"
import { showPopup } from "@store/actions/global";


interface Props {
    navigation: NavigationType;
    route: any;
    connections: Array<IWalletConnectStore>;
}

interface State {
    source: string;
    walletSession: IWalletConnectSession;
    dappSession: IWalletConnectSession;
    getCopyWalletConnectCode: boolean
}

const { t } = i18n;

export class _DappsWebViewScreen extends Component<Props, State> {

    clipboardListener: any;

    constructor(props: Props) {
        super(props);
        this.setStorage = this.setStorage.bind(this)
        this.getWalletConnect = this.getWalletConnect.bind(this)
        this.pasteCode = this.pasteCode.bind(this)
        this.state = {
            source: this.props.route.params?.session?.peerMeta?.url || "",
            walletSession: this.props.route.params?.session,
            dappSession: {
                connected: true,
                accounts: this.props.route.params?.session?.accounts,
                chainId: this.props.route.params?.session?.chainId,
                bridge: this.props.route.params?.session?.bridge,
                key: this.props.route.params?.session?.key,
                clientId: this.props.route.params?.session?.peerId,
                clientMeta: this.props.route.params?.session?.peerMeta,
                peerId: this.props.route.params?.session?.clientId,
                peerMeta: this.props.route.params?.session?.clientMeta,
                handshakeId: this.props.route.params?.session?.handshakeId,
                handshakeTopic: this.props.route.params?.session?.handshakeTopic
            },
            getCopyWalletConnectCode: false


        };
    }
    async componentDidMount() {
        const DAPP = this.props.connections.find((c: IWalletConnectStore) => {
            return c.session.peerMeta.url == this.state.walletSession.peerMeta.url
        })
        if (DAPP) {
            this.activeSession(DAPP.session, DAPP.network)
        }
        this.evaluateClipboard()
    }

    componentWillUnmount() {
        Clipboard.removeClipboardListener(this.clipboardListener);
    }

    async evaluateClipboard() {
        this.clipboardListener = Clipboard.addClipboardListener(({ contentTypes }: any) => {
             if (contentTypes.includes(Clipboard.ContentType.PLAIN_TEXT)) {
                 Clipboard.getStringAsync().then((content) => {
                     if (content.substring(0, 3) == "wc:") {
                         this.setState({ getCopyWalletConnectCode: true });
                         this.pasteCode()
                     }
                 });
             }
         });
         
     }

    async pasteCode() {

        const pasteText: string = await Clipboard.getStringAsync()

        if (pasteText !== undefined && pasteText.slice(0, 2) == "wc") {
            this.getWalletConnect(pasteText)
        } else {
            store.dispatch(showPopup({ type: "ERROR", message: t("wallet_connect_paste_error") }))
        }
    }

    async getWalletConnect(data?: string) {

        // await WalletConnectService.getInstance().getNewWalletConnect(data, () => null)
        this.setState({ getCopyWalletConnectCode: false })
        await Clipboard.setStringAsync('')
    }

    async activeSession(connector: IWalletConnectSession, selectedCurrency: string) {
        // await WalletConnectService.getInstance().getSessionUpdate(connector, selectedCurrency);
    }

    onMessage = (payload) => {
      
    };


    setStorage() {
        const DAPPsession = this.state.dappSession
        return (
            `(function() {
            window.localStorage.setItem('walletconnect', '${JSON.stringify(DAPPsession).replace(/'/gi, ' ')}');
            const walletconnectStorage = window.localStorage.getItem('walletconnect')
            window.ReactNativeWebView.postMessage('walletconnect');
            window.localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
           
          })();`)
    }


    render() {

        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.dappSession.clientMeta.name, 18) || ""}
                    type={HeaderType.Light}
                    connected={this.state.walletSession.connected}
                    rigthAvatar={{
                        onPress: () => null,
                        uri: this.state.dappSession.clientMeta.icons[0] || "",
                        alias:
                            this.state.dappSession.clientMeta.name || "",
                        size: 35,
                    }}
                    {...this.props} />
                <View style={{ flex: 1 }}>
                    {Platform.OS === "web" && (
                        <View style={{ flex: 1 }}>
                            <iframe src={this.state.source} style={{ border: "none" }} height={680} width={"100%"} />
                        </View>
                    )}
                    {Platform.OS !== "web" && (
                        <View style={{ flex: 1 }}>
                            <WebView
                                style={{ flex: 1 }}
                                source={{ uri: this.state.source }}
                                originWhitelist={['*']}
                                javaScriptEnabled={true}
                                domStorageEnabled={true}
                                injectedJavaScript={this.setStorage()}
                                pullToRefreshEnabled={true}
                                onMessage={this.onMessage}
                            >

                            </WebView>
                        </View>
                    )}
                </View>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        connections: state.walletconnect.connections,
    };
};

const mapDispatchToProps = (dispatch) => ({});
const DappsWebViewScreen = connect(mapStateToProps, mapDispatchToProps)(_DappsWebViewScreen);
export default DappsWebViewScreen;

const styles = StyleSheet.create({});
