import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import ExperienceService from "@core/services/ExperienceService";


interface Props {
    navigation: NavigationType;
    client: Client;
}

interface State {}

const { t } = i18n;

export class _ExperiencesHistoryScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
      
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("history")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}></Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExperiencesHistoryScreen = connect(mapStateToProps, mapDispatchToProps)(_ExperiencesHistoryScreen);

export default ExperiencesHistoryScreen;
