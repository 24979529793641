import { addConnection, setConnections } from "@store/actions/walletconnect.actions";
import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready } from "@store/actions/global";

export interface IWalletConnectStore {
    _id?: string;
    client?: string;
    network: string;
    session: IWalletConnectSession | null;
    extraData?: string;
}

export interface IWalletConnectSession {
    connected: boolean;
    accounts: string[];
    chainId: number;
    bridge: string;
    key: string;
    clientId: string;
    clientMeta: IClientMeta | null;
    peerId: string;
    peerMeta: IClientMeta | null;
    handshakeId: number;
    handshakeTopic: string;
}

export interface IClientMeta {
    description: string;
    url: string;
    icons: string[];
    name: string;
}

export default class WalletConnectDataService {
    private static instance: WalletConnectDataService;

    constructor() {}

    static getInstance(): WalletConnectDataService {
        if (!WalletConnectDataService.instance) {
            WalletConnectDataService.instance = new WalletConnectDataService();
        }
        return WalletConnectDataService.instance;
    }

    async storeConnection(session: IWalletConnectSession, selectedCurrency: string) {
        const connection = await this.addSessionAsync(session, selectedCurrency);
        store.dispatch(addConnection(connection));
    }

    async addSessionAsync(session: IWalletConnectSession, selectedCurrency: string, extraData?: string) {
        try {
            store.dispatch(loading());
            let body: IWalletConnectStore = {
                network: selectedCurrency,
                session: session,
                extraData: extraData || ""
            };
            const resp = await axios.post(`${getEnv("API_URL")}wallet-connect/sessions`, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return resp.data;
        } catch (error) {
            store.dispatch(ready());
            return error;
        }
    }

    async removeSessionStored(session: IWalletConnectSession) {
        const connector = store.getState().walletconnect.connections.find((c: IWalletConnectStore) => {
            return c.session.key == session.key;
        });
        await this.removeSessionAsync(connector);
        const sessions = store.getState().walletconnect.connections;
        const updateState = await sessions.filter((s: IWalletConnectStore) => {
            if (s.session !== null) {
                return connector.session.key !== s.session.key;
            }
        });

        
    }

    

    async removeSessionAsync(connection: IWalletConnectStore) {
        try {
            store.dispatch(loading());
            const id = connection._id;
            const resp = await axios.delete(`${getEnv("API_URL")}wallet-connect/sessions/${id}`, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
        } catch (error) {
            store.dispatch(ready());
            return error;
        }
    }

    async setStoredState() {
        const sessions: Array<IWalletConnectStore> = await this.getAllSessionsAync();
        const setSessions = sessions.length > 0 ? sessions.map((WC: IWalletConnectStore) => {
            WC.session.connected = false;
            return WC;
        }) : [];
        
    }

    async getAllSessionsAync() {
        try {
            const resp = await axios.get(`${getEnv("API_URL")}wallet-connect/sessions`, {
                headers: await ApiService.getAuthHeaders(),
            });
            return resp.data;
        } catch (error) {
            return error;
        }
    }

    async updateConnection(connector: IWalletConnectSession) {
        const reducerConnections = await store.getState().walletconnect.connections;
        const updateState = await reducerConnections.map((c: IWalletConnectStore) => {
            if (c.session !== null && connector.key == c.session.key) {
                c.session.connected = !c.session.connected;
            }
            return c;
        });
       
    }
}
