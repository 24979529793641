import NavigationInstantiator from "./helpers/NavigationInstantiator"
import DappsScreen from "@screens/dapps/DappsScreen"
import DappsWebViewScreen from "@screens/dapps/DappsWebViewScreen"
import DappsWebViewExploreScreen from "@screens/dapps/DappsWebViewExploreScreen"
import { ProfileNavigator } from "./ProfileNavigator"


const DappsNavigatorScreens = {
    DaapsMain: {
        routeName: "DaapsMain",
        title: "Dapps",
        component: DappsScreen,
    },

    DaapsWebView: {
        routeName: "DaapsWebView",
        title: "Dapp Explore",
        component: DappsWebViewScreen,
    },

    DaapsWebViewExplore: {
        routeName: "DaapsWebViewExplore",
        title: "Dapp Explore",
        component: DappsWebViewExploreScreen,
    },

    Profile: {
        routeName: "Profile",
        title: "Profile",
        component: ProfileNavigator,
    },
}
const DappsNavigator = NavigationInstantiator(DappsNavigatorScreens)

export { DappsNavigator, DappsNavigatorScreens }
