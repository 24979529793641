import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { StyleSheet, View } from "react-native";

import i18n from "@i18n/i18n";

import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { ASSET, AssetHistory, REDEEMABLE, RedeemableHistory, ExperienceStatus } from "@custom-types/ExpercienceModel";
import ImageBase from "@components/image/ImageBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { getColorOpacity } from "@utils/helpers/global/global";
import Icon from "@components/icons";
import { ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import AvatarBase from "@components/avatar/AvatarBase";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import { getRedeemableColorStatus, getHistoryStatusIcon } from "./helpers/redeemable.helper";
import moment from "moment";
import { Client } from "@custom-types/Client";

interface Props {
    history?: AssetHistory;
    asset: ASSET;
    client: Client;
}

interface State {}

const { t } = i18n;

export default class AssetHistoryCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Card
                style={styles.card}
                icon={
                    <View
                        style={[
                            styles.icon,
                            {
                                backgroundColor: colors.secondary,
                            },
                        ]}
                    >
                        <Icon name={getHistoryStatusIcon(this.props.history?.action)} size={20} color={colors.white} />
                    </View>
                }
                left={
                    <View style={{ paddingLeft: 10, justifyContent: "center" }}>
                        <View>
                            {this.props.history.action == ExperienceStatus.CREATED && (
                                <Row style={{ marginLeft: -4 }}>
                                    <AvatarBase
                                        size={20}
                                        alias={this.props.asset.assetOption?.name}
                                        uri={
                                            this.props.asset?.merchant.image?.thumbnail ||
                                            this.props.asset?.merchant.image?.cover
                                        }
                                    ></AvatarBase>
                                    <RegularText style={{ paddingLeft: 5 }} fontSize={12}>
                                        {t("created_by")}{" "}
                                        <BoldText fontSize={12}>
                                            {trimHelper(this.props.asset?.merchant?.name, 20)}
                                        </BoldText>
                                    </RegularText>
                                </Row>
                            )}

                            {this.props.history.action == ExperienceStatus.ASSIGNED && (
                                <Row style={{ marginLeft: -4 }}>
                                    <AvatarBase
                                        size={20}
                                        alias={this.props.history?.currentOwner?.alias}
                                        uri={this.props.history?.currentOwner?.profileImagePath?.thumbnail}
                                    ></AvatarBase>
                                    <RegularText style={{ paddingLeft: 5 }} fontSize={12}>
                                        {t("assigned_to")}
                                        <BoldText fontSize={12}>
                                            {trimHelper(this.props.history?.currentOwner?.alias, 20)}
                                        </BoldText>
                                    </RegularText>
                                </Row>
                            )}

                            {this.props.history.action == ExperienceStatus.TRANSFERED && (
                                <Row style={{ marginLeft: -4 }}>
                                    <AvatarBase
                                        size={20}
                                        alias={this.props.history?.currentOwner?.alias}
                                        uri={this.props.history?.currentOwner?.profileImagePath?.thumbnail}
                                    ></AvatarBase>

                                    {this.props.history.previousOwner?._id == this.props?.client?._id && (
                                        <RegularText style={{ paddingLeft: 5 }} fontSize={12}>
                                            {t("transferred_to")}
                                            <BoldText fontSize={12}>
                                                {trimHelper(this.props.history?.currentOwner?.alias, 20)}
                                            </BoldText>
                                        </RegularText>
                                    )}
                                    {this.props.history.currentOwner?._id == this.props?.client?._id && (
                                        <RegularText style={{ paddingLeft: 5 }} fontSize={12}>
                                            {t("received_from_redeemable")}
                                            <BoldText fontSize={12}>
                                                {trimHelper(this.props.history?.previousOwner?.alias, 20)}
                                            </BoldText>
                                        </RegularText>
                                    )}

                                    {this.props.history.currentOwner?._id !== this.props?.client?._id &&
                                        this.props.history.previousOwner?._id !== this.props?.client?._id && (
                                            <RegularText style={{ paddingLeft: 5 }} fontSize={12}>
                                                {t("transferred_to")}
                                                <BoldText fontSize={12}>
                                                    {trimHelper(this.props.history?.previousOwner?.alias, 20)}
                                                </BoldText>{" "}
                                                {t("to").toLowerCase()}{" "}
                                                <BoldText fontSize={12}>
                                                    {trimHelper(this.props.history?.currentOwner?.alias, 20)}
                                                </BoldText>
                                            </RegularText>
                                        )}
                                </Row>
                            )}
                        </View>
                        <Row
                            style={{
                                paddingTop: 5,
                                justifyContent: "flex-start",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <View>
                                <Icon color={colors.text} size={12} name="calendar-minus"></Icon>
                            </View>
                            <RegularText fontSize={12} style={{ paddingLeft: 5 }}>
                                {moment(this.props.history?.createdAt).format("LL")}
                                {(this.props.history?.action == ExperienceStatus.ASSIGNED ||
                                    this.props.history?.action == ExperienceStatus.TRANSFERED) && (
                                    <BoldText fontSize={12}>
                                        {" ("}
                                        {moment(this.props.history?.createdAt).format("hh:mm A")}
                                        {") "}
                                    </BoldText>
                                )}
                            </RegularText>
                        </Row>
                    </View>
                }
            ></Card>
        );
    }
}

const styles = StyleSheet.create({
    card: {},
    status: {
        borderRadius: 6,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },

    icon: {
        width: 35,
        height: 35,
        marginRight: 15,
        borderRadius: 35,
        alignItems: "center",
        justifyContent: "center",
    },
});
