import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Row from "@base/Row";
import TransactionDetailComponent from "./TransactionDetailComponent";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
    selectedTransaction: number;
}

interface State {
    showMessage: boolean;
}

const { t } = i18n;

export class _TransactionDetailScreen extends Component<Props, State> {
    protected transaction: any;
    protected currency: Currency;

    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.transaction = this.currency.getTransaction(this.props.selectedTransaction);
        this.state = {
            showMessage: false,
        };
    }

    // async onPress(id: string) {
    //     const url = env.EXPLORER_URL + id
    //     const supported = await Linking.canOpenURL(url)

    //     if (supported) {
    //         await Linking.openURL(url)
    //     } else {
    //         //console.warn(`Don't know how to open this URL: ${url}`);
    //     }
    // }



    render() {
        return (
            <ScreenWrapper type="scroll">
                <Header title={t("transaction_detail")} type={HeaderType.Light} {...this.props} />
                <Container>
                    <TransactionDetailComponent
                        currency={this.currency}
                        transaction={this.transaction}
                
                    />

                    {/*
                    <Card onPress={() => this.onPress(this.transaction.id)}>
                        <RegularText color={colors.text} align='center'>
                            View more
                        </RegularText>
                    </Card>
                    */}
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const TransactionDetailScreen = connect(mapStateToProps, mapDispatchToProps)(_TransactionDetailScreen);

export default TransactionDetailScreen;

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    addressContainer: {
        marginTop: 5,
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.secondaryShadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
});
