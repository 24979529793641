import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { ASSET, EXPERIENCE } from "@custom-types/ExpercienceModel";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ImageBase from "@components/image/ImageBase";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import Icon from "@components/icons";
import AvatarBase from "@components/avatar/AvatarBase";
import ExperienceService from "@core/services/ExperienceService";
import store from "@store/index";
import { updateAsset } from "@store/actions/experiences.actions";
import { NavigationType } from "@custom-types/NavigationType";
import { ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { getColorOpacity } from "@utils/helpers/global/global";
import Row from "@base/Row";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    asset: ASSET;
    onlyView?: boolean;
    onPress?: (asset: ASSET) => void;
    size?: number;
}

interface State {}

const { t } = i18n;

export class _AssetCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToAsset = this.navigateToAsset.bind(this);
        this.state = {};
    }

    async setFavorite() {
        ExperienceService.getInstance().setAssetFavorite(this.props.asset);
        let asset: ASSET = this.props.asset;
        asset.favorite = !this.props.asset.favorite;
        store.dispatch(updateAsset(asset));
    }

    navigateToAsset() {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.Asset.routeName,
            params: { asset: this.props.asset },
        });
    }

    render() {
        return (
            <PressableBase
                onPress={() =>
                    this.props.onPress ? this.props.onPress(this.props.asset) : this.navigateToAsset()
                }
                disabled={this.props.onlyView && !this.props.onPress}
                style={{
                    borderRadius: settings.cardRadius,
                    margin: 5,
                    overflow: "hidden",
                    flex: 1,
                }}
            >
                <ImageBase
                    style={{ height: this.props.size || 175, width: this.props.size, resizeMode: "cover" }}
                    blurRadius={this.props.asset?.archivedAt ? 10 : 0}
                    uri={
                        this.props.asset.assetOption?.image?.carousel ||
                        this.props.asset.assetOption?.image?.cover ||
                        ""
                    }
                ></ImageBase>
                <View style={{ position: "absolute", left: 10, top: 10 }}>
                    <AvatarBase
                        size={30}
                        uri={this.props.asset.merchant?.image?.thumbnail}
                        alias={this.props.asset.merchant?.name}
                    ></AvatarBase>
                </View>
                {this.props.asset.archivedAt && (
                    <View
                        style={{
                            position: "absolute",
                            bottom: 15,
                            left: -10,
                            borderRadius: 15,
                            paddingRight: 10,
                            paddingLeft: 18,
                            paddingVertical: 3,
                            backgroundColor: getColorOpacity(colors.grey, 0.8),
                        }}
                    >
                        <Row style={{ alignItems: "center" }}>
                            <Icon
                                size={12}
                                style={{ paddingRight: 5 }}
                                color={colors.labelText}
                                name={"archive"}
                            ></Icon>

                            <RegularText color={colors.labelText} fontSize={10}>
                                {t("archived").toUpperCase()}
                            </RegularText>
                        </Row>
                    </View>
                )}
                {!this.props.asset?.archivedAt && !this.props.onlyView && (
                    <PressableBase
                        onPress={() => this.setFavorite()}
                        style={{ position: "absolute", top: 10, right: 10 }}
                    >
                        {this.props.asset.favorite ? (
                            <Icon color={colors.yellow} size={20} name="star-fill" />
                        ) : (
                            <Icon color={colors.grey} size={20} name="star" />
                        )}
                    </PressableBase>
                )}

                {!this.props.asset.archivedAt && (
                    <View
                        style={{
                            position: "absolute",
                            bottom: 15,
                            left: 10,
                            backgroundColor: colors.complementary || colors.secondary,
                            paddingHorizontal: 10,
                            paddingVertical: 2,
                            borderRadius: settings.cardRadius,
                        }}
                    >
                        <RegularText color={colors.labelText} fontSize={12} numberOfLines={1}>
                            {trimHelper(this.props.asset?.assetOption?.name, 15)}
                        </RegularText>
                    </View>
                )}
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const AssetCard = connect(mapStateToProps, mapDispatchToProps)(_AssetCard);

export default AssetCard;
