import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready, setMessageLoader, setProgresiveLoader, showPopup } from "@store/actions/global";
import i18n from "@i18n/i18n";
import { OAuthProvider } from "./OAuthProvider";
import { AuthType, Client } from "@custom-types/Client";
import Constants from "expo-constants";

const { t } = i18n;

interface UserData {
    email: string;
    family_name: string;
    given_name: string;
    id: string;
    locale: string;
    name: string;
    picture: string;
    full_picture: string;
    verified_email: boolean;
}

export default class GoogleAuthService implements OAuthProvider {
    static instance: GoogleAuthService;
    accessToken: string = "";
    idToken: string = "";
    userData: UserData;
    fileId: string = "";

    constructor(data?: { tokens: any; userData: any }) {
        if (data) {
            this.setTokens(data.tokens);
            this.setUserData(data.userData);
        }
    }

    static getInstance(data?: any): GoogleAuthService {
        if (!GoogleAuthService.instance) {
            GoogleAuthService.instance = new GoogleAuthService(data);
        }
        return GoogleAuthService.instance;
    }

    async getAuthHeaders() {
        const headers = {
            ...ApiService.headers(),
            "Google-Token-Id": this.getTokens().idToken,
        };
        return headers;
    }

    allowCloud() {
        return true;
    }

    getTokens() {
        return {
            accessToken: this.accessToken,
            idToken: this.idToken,
        };
    }

    setTokens(auth: any) {
        this.accessToken = auth.accessToken;
        this.idToken = auth.idToken;
    }

    getClientData() {
        return {
            userData: this.userData,
        };
    }

    setUserData(data: any) {
        this.userData = data.userData;
    }

    async auth(data: any) {
        this.setTokens(data);
        this.setUserData(data);
        return await this.syncUserData();
    }

    getRegisterEndpoint() {
        return "google";
    }

    getProcessedClientData() {
        return {
            email: this.userData.email,
            avatar: this.userData.full_picture,
            name: this.userData.name,
            alias: `${this.userData.email.split("@")[0]}${Math.round(Math.random() * 1000)}`,
            authType: AuthType.GOOGLE,
        };
    }

    async syncUserData() {
        store.dispatch(loading());
        try {
            let url = "https://www.googleapis.com/userinfo/v2/me";

            const resp = await axios.get(url, {
                headers: { Authorization: `Bearer ${this.accessToken}` },
            });

            if (resp.data?.picture && resp.data?.picture.endsWith("=s96-c")) {
                resp.data.full_picture = resp.data?.picture.replace(/=s96-c$/, "=s360-c-no");
            }
            store.dispatch(ready());
            this.userData = resp.data;
            return resp.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async uploadKeys(keys: { shareA: string; shareB: string }) {
        store.dispatch(loading());
        const fileContent = keys.shareA.toString();
        //const folderName = `${getEnv('id') } (Do not remove)`;
        const fileName = `${Constants.expoConfig.name} keys (Do not remove)`;

        try {
            const driveRes = await axios.post(
                "https://www.googleapis.com/upload/drive/v3/files?uploadType=media",
                fileContent,
                {
                    headers: {
                        "Authorization": `Bearer ${this.accessToken}`,
                        "Content-Type": "text/plain",
                    },
                },
            );

            this.fileId = driveRes.data.id;

            axios.patch(
                `https://www.googleapis.com/drive/v3/files/${this.fileId}`,
                {
                    //addParents: [folderId],
                    name: fileName,
                },
                {
                    headers: {
                        "Authorization": `Bearer ${this.accessToken}`,
                        "Content-Type": "application/json",
                    },
                },
            );

            const apiRes = await axios.post(
                `${getEnv("API_URL")}account/recovery-data`,
                {
                    method: "GOOGLE_DRIVE",
                    key: keys.shareB.toString(),
                    fileId: this.fileId,
                },
                { headers: await ApiService.getAuthHeaders() },
            );

            store.dispatch(ready());
            return apiRes.data;
        } catch (error) {
            store.dispatch(ready());
            console.warn(error);
            throw new Error("Can not upload keys");
        }
    }

    async recoverSeed(client: Client): Promise<any> {
        store.dispatch(loading());

        const { data } = await axios.get(`${getEnv("API_URL")}account/${client.getId()}/recovery-data/GOOGLE_DRIVE`, {
            headers: await this.getAuthHeaders(),
        });

        if (!data) {
            throw new Error("There is not recovery data");
        }

        const shareA = data.key;
        const shareB = await this.handleDownload(data.fileId);
        store.dispatch(ready());
        return { shareA, shareB };
    }

    handleDownload = async (fileId) => {
        if (fileId) {
            store.dispatch(loading());
            try {
                const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                });
                store.dispatch(ready());
                return response.data;
            } catch (error: any) {
                if (error?.response?.data?.error?.code == 401) {
                    throw new Error("unauthorized");
                }
                store.dispatch(ready());
                console.error("Error al descargar el archivo:", error);
            }
        } else {
            store.dispatch(ready());
            //@TODO: Manejar mejor este error
            console.warn("No se ha subido ningún archivo aún.");
        }
    };
}
