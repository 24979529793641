import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { MEMBERSHIP, REDEEMABLE, RedeemableType, ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { Route } from "@react-navigation/native";
import SearchBase from "@components/search/SearchBase";
import { FlatList, View } from "react-native";
import RedeemableWideCard from "./components/RedeemableWideCard";
import SwitchCard from "@components/cards/SwitchCard";
import ExperienceService from "@core/services/ExperienceService";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import RegularText from "@base/RegularText";
import MembershipCard from "./components/MembershipCard";

interface Props {
    navigation: NavigationType;
    route: Route<string, {}>;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State {}

const { t } = i18n;

export class _SectionMembershipScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.state = {};
    }

    renderItem = ({ item }) => {
        return <MembershipCard membership={item} {...this.props}></MembershipCard>;
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("membership")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <FlatList
                        data={this.props.experiences["membership"].docs}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                        initialNumToRender={5}
                        maxToRenderPerBatch={10}
                        numColumns={2}
                        columnWrapperStyle={{ justifyContent: "space-between", flex: 1 }}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SectionMembershipScreen = connect(mapStateToProps, mapDispatchToProps)(_SectionMembershipScreen);

export default SectionMembershipScreen;
