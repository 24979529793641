import i18n from "@i18n/i18n";
import WalletConnectService from "@core/services/WalletConnectService";
import { IWalletConnectSession } from "@core/services/WalletConnectDataService";

const { t } = i18n;

export const injectFunction = (fn, messageType: string) => {
    return `(${fn.toString()})(); 
    (function(){
        window.ReactNativeWebView.postMessage(JSON.stringify({messageType: "${messageType}"}))
    })()`;
};

export const clearData = (status?: string) => {
    const fn = function () {
        try {
            localStorage.clear();
        } catch (e) {}
        window.history.forward();
    };

    return injectFunction(fn, status || "storageCleaned");
};

export const getWevViewRef = () => {
    const fn = function () {};

    return injectFunction(fn, "webViewLoaded");
};

export const getFirstConnection = () => {
    const fn = function () {
        try{
      
        window.addEventListener(
            "message",
            (event) => {
                if (event.data?.length) {
                    // @ts-ignore
                    window.ReactNativeWebView.postMessage(event.data);
                    let modal = document.getElementById("walletconnect-wrapper")
                    if(modal){
                        modal.style.opacity = "0";
                    }
                }
            },
            false,
        );

        window.postMessage(JSON.stringify({ messageType: "newWalletConnect" }));
    } catch(e){
        alert(e)
    }
    };

    return injectFunction(fn, "");
};

export const setWebViewJS = () => {
    const fn = function () {
        try {
            const storage = window.localStorage.getItem("walletconnect");
            if (storage) {
                // @ts-ignore
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ messageType: "returnStorage", localStorage: JSON.parse(storage) }),
                );
            } else {
                // @ts-ignore
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ messageType: "returnStorage", localStorage: "empty" }),
                );
            }
            window.addEventListener(
                "message",
                (event) => {
                    if (event.data?.length) {
                        // @ts-ignore
                        window.ReactNativeWebView.postMessage(event.data);
                    }
                },
                false,
            );
        } catch (e) {}
    };

    return injectFunction(fn, "");
};

export const setLocalStorage = (DAPPsession, status?: string) => {
    return `(function() {
        window.localStorage.setItem('walletconnect', '${JSON.stringify(DAPPsession).replace(/'/gi, " ")}');
        window.ReactNativeWebView.postMessage(JSON.stringify({messageType: "${status || ""}"}))
  })();`;
};

export const injectWindowPostMessage = (message: string) => {
    return `(function() {
            window.postMessage('${message}');            
      })();`;
};

export const generateDappSessionData = (storedSession: IWalletConnectSession) => {
    const dappSession: IWalletConnectSession = {
        connected: true,
        accounts: storedSession.accounts,
        chainId: storedSession.chainId,
        bridge: storedSession.bridge,
        key: storedSession.key,
        clientId: storedSession.peerId,
        clientMeta: storedSession.peerMeta,
        peerId: storedSession.clientId,
        peerMeta: storedSession.clientMeta,
        handshakeId: storedSession.handshakeId,
        handshakeTopic: storedSession.handshakeTopic,
    };
    return dappSession;
};
