import React, { Component } from "react";
import { StyleSheet, View, Keyboard, EmitterSubscription, Platform } from "react-native";
import Container from "@base/Container";
import FooterButtons from "./FooterButtons";
import { colors } from "@styles/globalStyles";
import Sidebar from "@components/sidebar/Sidebar";
import ScannerMain from "@screens/scanner/ScannerMain";
import { ModuleControlService, SkinType } from "@core/services/ModuleControlService";
import FooterButtonEvents from "./FooterButtonsEvents";
import FooterButtonCrypto from "./FooterButtonsCrypto";

interface Props {
    navigation: any;
}

interface State {
    isVisible: boolean;
    showSidebar: boolean;
    showScanner: boolean;
}

export class Footer extends Component<Props, State> {
    private keyboardWillShowSub: EmitterSubscription;
    private keyboardWillHideSub: EmitterSubscription;

    constructor(props) {
        super(props);
        this.setFooter = this.setFooter.bind(this);
        this.keyboardWillShow = this.keyboardWillShow.bind(this);
        this.keyboardWillHide = this.keyboardWillHide.bind(this);

        this.state = {
            isVisible: true,
            showSidebar: false,
            showScanner: false,
        };
    }

    componentDidMount() {
        this.keyboardWillShowSub = Keyboard.addListener(
            Platform.OS == "ios" ? "keyboardWillShow" : "keyboardDidShow",
            this.keyboardWillShow,
        );
        this.keyboardWillHideSub = Keyboard.addListener(
            Platform.OS == "ios" ? "keyboardWillHide" : "keyboardDidHide",
            this.keyboardWillHide,
        );
    }

    componentWillUnmount() {
        this.keyboardWillShowSub.remove();
        this.keyboardWillHideSub.remove();
    }

    keyboardWillShow = (event) => {
        this.setState({
            isVisible: false,
        });
    };

    keyboardWillHide = (event) => {
        this.setState({
            isVisible: true,
        });
    };

    setFooter(showSidebar: boolean) {
        // this.setState({ showSidebar: showSidebar });
        if (showSidebar) {
            this.setState({ showSidebar: showSidebar });
        }
        if (!showSidebar) {
            setTimeout(() => {
                this.setState({ showSidebar: showSidebar });
            }, 300);
        }
    }

    render() {
        return (
            <>
                <View
                    style={
                        this.state.showSidebar && {
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-end",
                        }
                    }
                >
                    <Sidebar setFooter={this.setFooter} {...this.props}></Sidebar>
                    <View style={[styles.footer, !this.state.isVisible ? styles.hidde : {}]}>
                        <View style={{ flex: 1, height: "100%" }}>
                            <Container>
                                {ModuleControlService.getInstance().getSkin(SkinType.base) && (
                                    <FooterButtons {...this.props} />
                                )}
                                {ModuleControlService.getInstance().getSkin(SkinType.events) && (
                                    <FooterButtonEvents {...this.props} />
                                )}
                                {ModuleControlService.getInstance().getSkin(SkinType.crypto) && (
                                    <FooterButtonCrypto {...this.props} />
                                )}
                            </Container>
                        </View>
                    </View>
                </View>
                {this.state.showSidebar && <View style={{ height: 60, zIndex: -99999 }}></View>}

                <ScannerMain {...this.props}></ScannerMain>
            </>
        );
    }
}

const styles = StyleSheet.create({
    footer: {
        alignSelf: "flex-end",
        backgroundColor: colors.tertiary,
        height: 60,
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        paddingBottom: 10,
    },
    hidde: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
        width: "100%",
        height: "100%",
    },
});
