import React, { Component } from "react";
import { View, StyleSheet, Image } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import { colors, settings } from "@styles/globalStyles";
import { LinearGradient } from "expo-linear-gradient";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { getColorOpacity } from "./../../utils/helpers/global/global";
import Icon from "@components/icons";
import Wallet from "@core/wallet/Wallet";
import i18n from "@i18n/i18n";
import ImageBase from "@components/image/ImageBase";
import AvatarBase from "@components/avatar/AvatarBase";
import PressableBase from "@base/PressableBase";

interface Props {
    title: string;
    description?: string;
    views?: string;
    icon?: string;
    url?: string;
    connected?: boolean;
    network?: string;
    onPress: () => void;
    onLongPress?: () => void;
}

const { t } = i18n;

export default class DappCard extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <PressableBase onPress={this.props.onPress} onLongPress={this.props.onLongPress} style={styles.wrapper}>
                <LinearGradient
                    style={{
                        position: "absolute",
                        width: "120%",
                        height: "120%",
                    }}
                    colors={[getColorOpacity(colors.shadow, 1), getColorOpacity(colors.tertiary, 0.5)]}
                ></LinearGradient>
                <Image
                    style={{
                        position: "absolute",
                        opacity: 0.5,
                        width: "200%",
                        height: "200%",
                        resizeMode: "cover",
                    }}
                    blurRadius={30}
                    source={{ uri: this.props.icon }}
                />
                {this.props.connected && (
                    <View
                        style={{
                            position: "absolute",
                            top: 11,
                            right: 11,
                        }}
                    >
                        <View
                            style={{
                                paddingHorizontal: 5,
                                paddingVertical: 2,
                                borderRadius: 6,
                                backgroundColor: getColorOpacity(colors.green, 0.5),
                            }}
                        >
                            <RegularText fontSize={8}>{t("connected")}</RegularText>
                        </View>
                        {/* ) : (
                        <View
                                style={{
                                    paddingHorizontal: 5,
                                    paddingVertical: 2,
                                    borderRadius: 6,
                                    backgroundColor: getColorOpacity(colors.grey, 0.5),
                                }}
                        >
                            <RegularText fontSize={8}>{t('disconnected')}</RegularText>
                        </View>
                    )} */}
                    </View>
                )}

                {this.props.network && (
                    <View
                        style={{
                            position: "absolute",
                            top: 6,
                            left: 10,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <View
                            style={{
                                width: 24,
                                height: 24,
                                borderRadius: 12,
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: Wallet.getInstance().findCurrencyById(this.props.network).getColor(),
                            }}
                        >
                            <Icon
                                name={Wallet.getInstance().findCurrencyById(this.props.network).getIcon()}
                                size={15}
                                color="white"
                            />
                        </View>
                    </View>
                )}

                {this.props.views && (
                    <View
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            backgroundColor: colors.secondary,
                            paddingHorizontal: 6,
                            paddingVertical: 1,
                            borderRadius: 8,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <RegularText color={colors.white} align="center" fontSize={10}>
                            {this.props.views}
                        </RegularText>
                        <Icon
                            style={{ marginLeft: 4 }}
                            name={"person-fill"}
                            size={10}
                            color={colors.white}
                            align="center"
                        />
                    </View>
                )}

                <View style={{ marginTop: 30 }}>
                    <AvatarBase
                        alias={this.props.title}
                        uri={this.props.icon}
                        size={70}
                        overlayContainerStyle={colors.secondaryShadow}
                    />
                </View>

                <View style={{ flex: 1 }}>
                    <BoldText align="center" fontSize={14} color={colors.white} style={{ marginTop: 10 }}>
                        {trimHelper(this.props.title, 18)}
                    </BoldText>
                </View>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        marginHorizontal: 3,
        marginVertical: 3,
        height: 160,
        borderRadius: settings.cardRadius,
        padding: 10,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
});
