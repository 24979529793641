import PressableBase from "@base/PressableBase";
import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { ImageStyle, Image, View, Platform, ActivityIndicator, Dimensions } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer-fixed";
import { SkypeIndicator } from "react-native-indicators";

interface Props {
    uri: string;
    blurRadius?: number;
    style?: ImageStyle;
    enableImageZoom?: boolean;
    enableLoader?: boolean;
    autoSize?: boolean;
    autoSizeWidth?: boolean;
    maxAutoSizeHight?: number;
    onClick?: () => void;
}

interface State {
    isWrong: boolean;
    imageHight: number | string;
    imageWidth: number | string;
}

export class ImageBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setImage = this.setImage.bind(this);
        this.setImageWidth = this.setImageWidth.bind(this);
        this.state = {
            isWrong: this.props.uri?.length < 1 || this.props.uri == undefined,
            imageHight: this.props.maxAutoSizeHight,
            imageWidth: "100%",
        };
    }
    componentDidMount() {
        if (this.props.autoSize) {
            Image.getSize(this.props.uri || "", (width, height) => this.setImage(width, height));
        }
        if (this.props.autoSizeWidth) {
            Image.getSize(this.props.uri || "", (width, height) => this.setImageWidth(width, height));
        }
    }

    setImageWidth = (width, height) => {
        this.setState({
            imageHight: this.props.style?.height || height,
            imageWidth:
                width / (height / (typeof this.props.style?.height === "number" ? this.props.style?.height : height)),
        });
    };

    setImage = (width, height) => {
        const screenWidth = Dimensions.get("window").width < 600 ? Dimensions.get("window").width : 600;

        if (this.props.maxAutoSizeHight && height > width) {
            this.setState({
                imageHight: this.props.maxAutoSizeHight,
                imageWidth: width / (height / this.props.maxAutoSizeHight),
            });
        } else {
            this.setState({ imageHight: height / (width / (screenWidth - 40)), imageWidth: screenWidth - 40 });
        }
    };

    render() {
        return (
            <View
                style={[
                    this.props.enableImageZoom && {
                        flex: 1,
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                    },
                ]}
            >
                {this.props.enableLoader && (
                    <View
                        style={{
                            position: "absolute",
                            borderRadius: this.props.style?.borderRadius || 0,
                            backgroundColor: "#D1D1D1",
                            width: this.props.autoSize || this.props.autoSizeWidth ? this.state.imageWidth : "100%",
                            height: this.props.autoSize || this.props.autoSizeWidth ? this.state.imageHight : "100%",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                        }}
                    >
                        {Platform.OS == "web" ? (
                            <ActivityIndicator size={60} color="white" />
                        ) : (
                            <SkypeIndicator size={80} animationDuration={2000} color="white" />
                        )}
                    </View>
                )}
                {this.props.enableImageZoom ? (
                    <ImageViewer
                        backgroundColor={this.props.enableLoader ? "transparent" : "#D1D1D1"}
                        style={
                            this.props.autoSize || this.props.autoSizeWidth
                                ? {
                                    height: this.state.imageHight,
                                    width: this.state.imageWidth,
                                    overflow: "hidden",
                                    borderRadius: this.props.style.borderRadius || 0,
                                }
                                : this.props.style
                        }
                        enableImageZoom={this.props.enableImageZoom}
                        renderIndicator={() => null}
                        onClick={this.props.onClick}
                        imageUrls={[{ url: this.props.uri }]}
                    ></ImageViewer>
                ) : (
                    <View>
                        {this.props.onClick ? (
                            <PressableBase
                                style={{
                                    flex: 1,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                                onPress={this.props.onClick}
                            >
                                <Image
                                    fadeDuration={500}
                                    onError={() => this.setState({ isWrong: true })}
                                    source={
                                        !this.state.isWrong
                                            ? { uri: this.props.uri }
                                            : require("@assets/images/placeholder_image.png")
                                    }
                                    blurRadius={this.props.blurRadius || 0}
                                    style={[
                                        this.props.style,
                                        {
                                            width: this.props.style?.width ? this.props.style.width : "100%",
                                            backgroundColor: this.props.enableLoader ? "transparent" : "#D1D1D1",
                                        },
                                        (this.props.autoSize || this.props.autoSizeWidth) && {
                                            height: this.state.imageHight,
                                            width: this.state.imageWidth,
                                        },
                                    ]}
                                />
                            </PressableBase>
                        ) : (
                            <Image
                                fadeDuration={500}
                                onError={() => this.setState({ isWrong: true })}
                                source={
                                    !this.state.isWrong
                                        ? { uri: this.props.uri }
                                        : require("@assets/images/placeholder_image.png")
                                }
                                blurRadius={this.props.blurRadius || 0}
                                style={[
                                    this.props.style,
                                    {
                                        width: this.props.style?.width ? this.props.style.width : "100%",
                                        backgroundColor: this.props.enableLoader ? "transparent" : "#D1D1D1",
                                    },
                                    (this.props.autoSize || this.props.autoSizeWidth) && {
                                        height: this.state.imageHight,
                                        width: this.state.imageWidth,
                                    },
                                ]}
                            />
                        )}
                    </View>
                )}
            </View>
        );
    }
}


export default ImageBase;
