import store from "@store/index";
import { hideScanner, loading, ready, showPopup } from "@store/actions/global";
import WalletConnectV2Service from "@core/services/WalletConnectV2Service";

export const isWalletConnect = (navigation, qrCode) => {
    const WalletConnectURL = /^wc:/i;
    switch (true) {
        case WalletConnectURL.test(qrCode):
            return getWalletConnect(qrCode);
        default:
            return;
    }
};

export const getWalletConnect = async (data?: string) => {
    store.dispatch(hideScanner());
    store.dispatch(loading());
    await WalletConnectV2Service.getInstance().pairDapp(data);
    store.dispatch(ready());
};
