import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready, setMessageLoader, setProgresiveLoader, showPopup } from "@store/actions/global";
import i18n from "@i18n/i18n";
import { OAuthProvider } from "./OAuthProvider";
import { AuthType, Client } from "@custom-types/Client";


const { t } = i18n;

const keys = {
    webClientId: "1039774532681-31tlfnlj6n64pcs7g0jld7hjpus87qi9.apps.googleusercontent.com",
};


interface UserData {

}

export default class AnonAuthService implements OAuthProvider {
    static instance: AnonAuthService;

    constructor(data?: { tokens: any, userData: any }) {

    }

    static getInstance(data?: any): AnonAuthService {
        if (!AnonAuthService.instance) {
            AnonAuthService.instance = new AnonAuthService(data);
        }
        return AnonAuthService.instance;
    }

    async getAuthHeaders() {
        const headers = {
            ...ApiService.headers(),
        };
        return headers
    }

    allowCloud() {
        return false
    }

    getTokens() {
        return {
            
        }
    }

    setTokens(auth:any) {
        
    }

    getClientData() {
        return {
            
        }
    }

    setUserData(data: any) {
        
    }

    async auth(data:any) {
    }

    getRegisterEndpoint() {
        return "anon";
    }

    getProcessedClientData() {
        return {
            email: null,
            avatar: null,
            name: '',
            alias: `user${Math.round(Math.random() * 1000)}`,
            authType: AuthType.ANON
        }
    }

    async uploadKeys(keys: {shareA: string, shareB: string}) {
        
    }

    async recoverSeed(client: Client): Promise<any> {
        
    }
}
