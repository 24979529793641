import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { Platform, StyleSheet, View } from "react-native";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { REDEEMABLE, RedeemableType } from "@custom-types/ExpercienceModel";
import ImageBase from "@components/image/ImageBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { getColorOpacity } from "@utils/helpers/global/global";
import Icon from "@components/icons";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import AvatarBase from "@components/avatar/AvatarBase";
import Row from "@base/Row";
import { getRedeemableColorStatus, getRedeemableIcon } from "./helpers/redeemable.helper";


interface Props {
    navigation?: NavigationType;
    redeemable: REDEEMABLE;
    onPress?: (redeemable: REDEEMABLE) => void;
}

interface State {}

const { t } = i18n;

export default class RedeemableWideCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
    }

    onPress() {
        this.props.onPress ? this.props.onPress(this.props.redeemable) : null;
    }

    render() {
        return (
            <Card style={styles.card} onPress={this.onPress}>
                <ImageBase
                    style={{ width: 120, height: 120, overflow: "hidden" }}
                    uri={this.props.redeemable.redeemableOption.image?.cover}
                    blurRadius={this.props.redeemable.archivedAt ? 10 : 0}
                ></ImageBase>

                {this.props.redeemable.archivedAt && (
                    <View
                        style={{
                            position: "absolute",
                            top: 15,
                            left: -10,
                            borderRadius: 15,
                            paddingRight: 10,
                            paddingLeft: 18,
                            paddingVertical: 3,
                            backgroundColor: getColorOpacity(colors.grey, 0.8),
                        }}
                    >
                        <Row style={{ alignItems: "center" }}>
                            <Icon
                                size={12}
                                style={{ paddingRight: 5 }}
                                color={colors.labelText}
                                name={"archive"}
                            ></Icon>

                            <RegularText color={colors.labelText} fontSize={10}>
                                {t("archived").toUpperCase()}
                            </RegularText>
                        </Row>
                    </View>
                )}

                <View
                    style={{
                        position: "absolute",
                        bottom: 15,
                        left: -10,
                        borderRadius: 15,
                        paddingRight: 10,
                        paddingLeft: 18,
                        paddingVertical: 3,
                        backgroundColor: getColorOpacity(getRedeemableColorStatus(this.props.redeemable.status), 0.8),
                    }}
                >
                    <BoldText color={colors.labelText} fontSize={10}>
                        {this.props.redeemable?.status?.toUpperCase()}
                    </BoldText>
                </View>
                <View style={styles.circleWrapper}>
                    <View style={styles.circle}>
                        <RegularText style={{ marginTop: Platform.OS == "web" ? -2 : 2 }}>
                            {getRedeemableIcon(this.props.redeemable.redeemableOption.type)}
                        </RegularText>
                    </View>
                </View>
                <View style={{ flex: 1, paddingLeft: 10, height: 125, paddingTop: 15 }}>
                    <Row style={{ alignContent: "center", alignItems: "center" }}>
                        <AvatarBase
                            alias={this.props.redeemable.experience.merchant.name}
                            uri={this.props.redeemable.experience.merchant.image?.thumbnail}
                            size={20}
                        ></AvatarBase>
                        <RegularText style={{ paddingLeft: 5 }} fontSize={12}>
                            {trimHelper(this.props.redeemable.experience.merchant.name, 20)}
                        </RegularText>
                    </Row>
                    <View style={{ paddingTop: 10 }}>
                        <BoldText>{this.props.redeemable.redeemableOption.name}</BoldText>
                        <RegularText style={{ paddingTop: 2 }} fontSize={12}>
                            {trimHelper(this.props.redeemable.redeemableOption.description, 50)}
                        </RegularText>
                    </View>
                </View>
            </Card>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        height: 120,
        justifyContent: "flex-end",
        flexDirection: "row",
    },
    circleWrapper: {
        position: "absolute",
        top: 8,
        right: 10,
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
});
