import React, { Component } from "react";
import { View, StyleSheet, Platform, BackHandler } from "react-native";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { WebView } from "react-native-webview";
import { IWalletConnectSession } from "@core/services/WalletConnectDataService";
import { IWalletConnectStore } from "@core/services/WalletConnectDataService";
import WalletConnectService from "@core/services/WalletConnectService";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import * as Clipboard from "expo-clipboard";
import { FloatButton } from "@base/FloatButton";
import store from "@store/index";
import { hideModal, showModal, showPopup } from "@store/actions/global";
import DappsService, { IDaapData } from "@core/services/DappsService";
import RegularText from "@base/RegularText";

interface Props {
    navigation: NavigationType;
    route: any;
    connections: Array<IWalletConnectStore>;
}

interface State {
    source: string;
    dapp: IDaapData;
    getCopyWalletConnectCode: boolean;
}

const { t } = i18n;

export class _DappsWebViewExploreScreen extends Component<Props, State> {
    clipboardListener: any;
    intervalID: any;
    constructor(props: Props) {
        super(props);
        this.removeStorage = this.removeStorage.bind(this);
        this.getWalletConnect = this.getWalletConnect.bind(this);
        this.pasteCode = this.pasteCode.bind(this);

        this.state = {
            source: this.props.route.params?.dapp?.url || "",
            dapp: this.props.route.params?.dapp,
            getCopyWalletConnectCode: false,
        };
    }
    async componentDidMount() {
        setTimeout(() => {
            store.dispatch(
                showModal({
                    avatar: this.state.dapp.logo,
                    title: this.state.dapp.name,
                    subtitle: t("dapps_connect_warning"),
                    question: t("dapps_connect_copy"),
                }),
            );
        }, 1000);

        this.evaluateClipboard();
    }

    componentWillUnmount() {
        if (this.clipboardListener) {
            Clipboard.removeClipboardListener(this.clipboardListener);
        }
        if (this.intervalID) {
            clearInterval(this.intervalID);
        }
    }

    async evaluateClipboard() {
        await Clipboard.setStringAsync("");
        if (Platform.OS !== "web") {
            this.clipboardListener = Clipboard.addClipboardListener(({ contentTypes }: any) => {
                if (contentTypes.includes(Clipboard.ContentType.PLAIN_TEXT)) {
                    Clipboard.getStringAsync().then((content) => {
                        if (content.substring(0, 3) == "wc:") {
                            this.setState({ getCopyWalletConnectCode: true });
                            this.pasteCode();
                        }
                    });
                }
            });
        } else {
            this.intervalID = setInterval(async () => {
                try {
                    // const QRCode = await Clipboard.getStringAsync();
          
                    // if (QRCode.substring(0, 3) == "wc:") {
                    //     this.setState({ getCopyWalletConnectCode: true });
                    //     this.pasteCode();
                    // }
                } catch (e) {}
            }, 5000);
        }
    }

    async pasteCode() {
        const pasteText: string = await Clipboard.getStringAsync();

        if (pasteText !== undefined && pasteText.slice(0, 2) == "wc") {
            this.getWalletConnect(pasteText);
        } else {
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("wallet_connect_paste_error"),
                }),
            );
        }
    }

    async getWalletConnect(data?: string) {
        // await WalletConnectService.getInstance().getNewWalletConnect(data, () => null);
        this.setState({ getCopyWalletConnectCode: false });
        await Clipboard.setStringAsync("");
    }

    removeStorage() {
        return `(function() {
            const walletconnectStorage = window.localStorage.getItem('walletconnect')
            if(walletconnectStorage){
                window.localStorage.removeItem('walletconnect')
                window.location.reload()
            }
            
            window.ReactNativeWebView.postMessage('walletconnect');
            window.localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
            
          })();`;
    }

    onMessage = (payload) => {};

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.dapp.name, 18) || ""}
                    type={HeaderType.Light}
                    rigthAvatar={{
                        onPress: () => null,
                        uri: this.state.dapp.logo || "",
                        alias: this.state.dapp.name || "",
                        size: 35,
                    }}
                    {...this.props}
                />
                <View style={{ flex: 1 }}>
                    {Platform.OS === "web" && (
                        <View style={{ flex: 1 }}>
                            <iframe src={this.state.source} style={{ border: "none" }} height={680} width={"100%"} />
                        </View>
                    )}
                    {Platform.OS !== "web" && (
                        <View style={{ flex: 1 }}>
                            <WebView
                                style={{ flex: 1 }}
                                source={{ uri: this.state.source }}
                                originWhitelist={["*"]}
                                javaScriptEnabled={true}
                                domStorageEnabled={true}
                                injectedJavaScript={this.removeStorage()}
                                pullToRefreshEnabled={true}
                                onMessage={this.onMessage}
                            ></WebView>
                        </View>
                    )}
                    {/* {this.state.getCopyWalletConnectCode && (
                        <FloatButton onPress={this.pasteCode} iconName={"clipboard"} />
                    )} */}
                </View>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        connections: state.walletconnect.connections,
    };
};

const mapDispatchToProps = (dispatch) => ({});
const DappsWebViewExploreScreen = connect(mapStateToProps, mapDispatchToProps)(_DappsWebViewExploreScreen);
export default DappsWebViewExploreScreen;

const styles = StyleSheet.create({});
