import * as React from "react";
import { Image } from "react-native";

function Logo(props) {
  return (
    <Image
      source={require('./logo.png')}
      style={{ width: 95, height: 35 }}
    />
  );
}

export default Logo;
