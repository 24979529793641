const darkColors = {
    primary: "#E1FC03",
    secondary: "#333d28",
    complementary: "#E1FC03",
    tertiary: "#0d0d0d",
    background: "#0F120C",
    gradientFrom: "#0F120C",
    gradientTo: "#0F120C",
    text: "#ffffff",
    white: "#ffffff",
    grey: "#5f5e5e",
    shadow: 'rgba(255,255,255,0.04)',
    secondaryShadow: "rgba(43, 51, 33, 0.5)",
    tertiaryShadow: "rgba(255, 255, 255, 0.12)",
    highlightedText: "#ffffff",
    mainButtonsColor: "#E1FC03",
    modalBackground: "#141810",
    activeFooter: "#E1FC03",
    floatButtonText: "#0F120C",
    inlineButtonBackground: "#E1FC03",
    inlineButtonText: "#0F120C",
    popupBackground: "#141810",
    avatarBase: "#919191",
};

const lightColors = darkColors;

const settings = {
    cardRadius: 15
};

export { darkColors, lightColors, settings };
