import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { Route } from "@react-navigation/native";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { colors, settings } from "@styles/globalStyles";
import { Blog } from "@custom-types/BlogModel";
import BoldText from "@base/BoldText";
import RenderHTML from "@components/renderHTML/RenderHTML";
import ImageBase from "@components/image/ImageBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import AvatarBase from "@components/avatar/AvatarBase";
import moment from "moment";
import CircleButton from "@base/CircleButton";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { blog: Blog }>;
}

interface State {
    blog: Blog;

    showDescription: boolean;
}

const { t } = i18n;

export class _BlogScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            blog: this.props.route?.params?.blog || null,
            showDescription: false,
        };
    }

    componentWillUnmount() {}

    render() {
        return (
            <ScreenWrapper>
                <Header title={trimHelper(this.state.blog?.title, 15)} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1 }}>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <View style={styles.wrapper}>
                            <View style={{ flex: 1, borderRadius: settings.cardRadius, overflow: "hidden" }}>
                                <ImageBase autoSize={true} uri={this.state.blog?.image?.square || ""}></ImageBase>
                            </View>

                            {this.state.blog?.tags && (
                                <Row style={styles.tags}>
                                    {this.state?.blog?.tags?.split(",").map((h) => {
                                        return (
                                            <View style={styles.tag}>
                                                <RegularText color={colors.white} fontSize={12}>
                                                    {"#"}
                                                    {h}
                                                </RegularText>
                                            </View>
                                        );
                                    })}
                                </Row>
                            )}

                            <View style={{ paddingHorizontal: 0 }}>
                                <BoldText style={{ paddingTop: 10 }} fontSize={22}>
                                    {this.state.blog?.title}
                                </BoldText>
                                <RegularText align="justify" style={{ paddingVertical: 10 }} fontSize={16}>
                                    {this.state.blog?.description}
                                </RegularText>
                                <Row style={{ alignItems: "center" }}>
                                    <AvatarBase
                                        alias={this.state.blog?.author?.alias}
                                        uri={this.state.blog?.author?.profileImagePath?.thumbnail}
                                        size={30}
                                    ></AvatarBase>
                                    <BoldText style={{ paddingLeft: 10 }}>
                                        <RegularText>{"By "}</RegularText>
                                        {this.state.blog?.author?.alias}
                                    </BoldText>
                                </Row>

                                <Row style={{ alignContent: "center", alignItems: "center", paddingTop: 10 }}>
                                    <CircleButton
                                        iconSize={16}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: 15,
                                            marginLeft: -5,
                                            marginRight: -5,
                                            marginBottom: 0,
                                        }}
                                        icon={"calendar-minus"}
                                        disableOnPress={true}
                                    ></CircleButton>

                                    <RegularText style={{ paddingLeft: 10 }}>
                                        {moment(this.state.blog?.createdAt).format("D MMMM, YYYY").toUpperCase()}
                                    </RegularText>
                                </Row>
                                <RenderHTML containerStyle={{}} source={this.state.blog?.content}></RenderHTML>
                            </View>
                        </View>
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        paddingBottom: 20,
    },
    tags: {
        width: "100%",

        flexWrap: "wrap",
        paddingTop: 15,
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    tag: {
        color: colors.white,
        backgroundColor: colors.complementary,
        marginRight: 10,
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 10,
        opacity: 0.9,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const BlogScreen = connect(mapStateToProps, mapDispatchToProps)(_BlogScreen);

export default BlogScreen;
