import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready } from "@store/actions/global";

export interface IDaapData {
    _id?: string | number;
    description: string;
    url: string;
    logo: string;
    name: string;
}

export default class DappsService {
    private static instance: DappsService;

    constructor() {}

    static getInstance(): DappsService {
        if (!DappsService.instance) {
            DappsService.instance = new DappsService();
        }
        return DappsService.instance;
    }

    async getAllDapps(page: number) {
        try {
            const resp = await axios.get(
                `${getEnv("API_URL")}dapps?page=${page}`,
                {
                    headers: await ApiService.getAuthHeaders(),
                },
            );
           
            return resp.data;
        } catch (error) {
            return error;
        }
    }

    async getSearchDapps(page: number, filter: string) {
        try {
            const resp = await axios.get(
                `${getEnv("API_URL")}dapps?page=${page}&name=${filter}`,
                {
                    headers: await ApiService.getAuthHeaders(),
                },
            );
            return resp.data;
        } catch (error) {
            return error;
        }
    }

    async getDappById(id: number) {
        try {
            const resp = await axios.get(
                `${getEnv("API_URL")}dapps/${id}`,
                {
                    headers: await ApiService.getAuthHeaders(),
                },
            );
            return resp.data;
        } catch (error) {
            return error;
        }
    }

    async registerDapp(body: IDaapData) {
    
        try {
            const resp = await axios.put(
                `${getEnv("API_URL")}dapps`,
                body,
                {
                    headers: await ApiService.getAuthHeaders(),
                },
            );
            return resp.data;
        } catch (error) {
            return error;
        }
    }
}
