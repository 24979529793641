import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { Route } from "@react-navigation/native";
import { ASSET, REDEEMABLE, RedeemableType } from "@custom-types/ExpercienceModel";
import ExperienceService from "@core/services/ExperienceService";
import { trimHelper } from "@utils/helpers/chat/chat.helper";

import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Card from "@base/Card";
import AvatarBase from "@components/avatar/AvatarBase";
import { nameSocialLengthHelper } from "@utils/helpers/social/social.helper";
import InlineButton from "@base/InlineButton";
import RedeemableWideCard from "./components/RedeemableWideCard";
import { ExperiencesNavigator, ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import store from "@store/index";
import { showPopup } from "@store/actions/global";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import AssetCard from "./components/AssetCard";

export interface IRedeemableSkeleton {
    from: string;
    to: string;
    timestamp?: number;
    id: string;
}

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<
        string,
        {
            asset: ASSET;
            client_to: Partial<Client>;
            data: {
                skeleton: IRedeemableSkeleton;
            };
        }
    >;
}

interface State {
    asset: ASSET;
    client_from: Partial<Client>;
    client_to: Partial<Client>;
    skeleton: IRedeemableSkeleton;
}

const { t } = i18n;

export class _ConfirmTransferAssetScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            asset: this.props.route.params?.asset,
            client_from: this.props.client,
            client_to: this.props.route.params?.client_to,
            skeleton: this.props.route.params?.data?.skeleton,
        };
        this.onConfirm = this.onConfirm.bind(this);
    }

    async onConfirm() {
        await ExperienceService.getInstance().confirmAssetTransfer(this.state.skeleton);
        await ExperienceService.getInstance().getAssets();
        await ExperienceService.getInstance().getMerchants();
        store.dispatch(showPopup({ type: "SUCCESS" }));
        this.props.navigation.navigate(TabsNavigatorScreens.Home.routeName, {
            screen: "HomeMain",
        });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm")} type={HeaderType.Light} {...this.props} />
                <ScrollView showsHorizontalScrollIndicator={false}>
                    <Container style={{ flex: 1, justifyContent: "space-between", paddingBottom: 20 }}>
                        <View style={{ paddingTop: 10 }}>
                            <View>
                                <View
                                    style={{
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        paddingBottom: 20,
                                        height: 300,
                                    }}
                                >
                                    <AssetCard
                                        asset={this.state.asset}
                                        size={300}
                                        onlyView={true}
                                        {...this.props}
                                    ></AssetCard>
                                </View>
                                <View>
                                    <BoldText> {t("owner")}</BoldText>
                                    <Card style={{ flexDirection: "row" }}>
                                        <AvatarBase
                                            uri={
                                                this.state.client_from?.profileImagePath
                                                    ? this.state.client_from?.profileImagePath.thumbnail
                                                    : ""
                                            }
                                            alias={this.state.client_from?.alias}
                                            size={35}
                                        />

                                        <RegularText style={{ textAlignVertical: "center", paddingLeft: 10 }}>
                                            @{trimHelper(this.state.client_from?.alias, 18)}
                                        </RegularText>
                                    </Card>
                                </View>
                                <View style={{ paddingTop: 15 }}>
                                    <BoldText>{t("transfer_to")}</BoldText>
                                    <Card style={{ flexDirection: "row",  }}>
                                        <AvatarBase
                                            uri={
                                                this.state.client_to?.profileImagePath
                                                    ? this.state.client_to?.profileImagePath.thumbnail
                                                    : ""
                                            }
                                            alias={this.state.client_to?.alias}
                                            size={35}
                                        />

                                        <RegularText style={{ textAlignVertical: "center", paddingLeft: 10 }}>
                                            @{trimHelper(this.state.client_to?.alias, 18)}
                                        </RegularText>
                                    </Card>
                                </View>
                            </View>
                        </View>
                        <View style={{ flex: 1, paddingTop: 25}}>
                        <InlineButton 
                        style={{marginHorizontal: 0}}
                        title={t("confirm")} onPress={this.onConfirm}></InlineButton>
                        </View>
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmTransferAssetScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmTransferAssetScreen);

export default ConfirmTransferAssetScreen;
