import { _SendScreen } from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";
import { _ConfirmSendScreen } from "@screens/wallet/send/ConfirmSendScreen";
import store from "@store/index";
import { setCustomMessage } from "@store/actions/chat.actions";
import { CustomMessageType } from "@custom-types/CustomMessageType";
import { ChatService } from "@core/services/ChatService";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";

export class _ConfirmSendCryptoScreen extends _ConfirmSendScreen {
    constructor(props: any) {
        super(props);
    }

    handleOnSuccessNavigate = (data) => {
        if (
            (this.props.customMessage &&
                this.props.customMessage.type == CustomMessageType.SendCrypto &&
                this.props.onSuccess) ||
            (this.props.customMessage &&
                this.props.customMessage.type == CustomMessageType.PayOrder &&
                this.props.onSuccess)
        ) {
            this.sendMessage(data);
            return true;
        }
    };
    sendMessage = async (data: any) => {
        if (this.props.customMessage.address.toLowerCase() == this.state.sendingTo.toLowerCase()) {
            this.props.customMessage.type = CustomMessageType.SendCrypto;
            this.props.customMessage.amount = this.state.amount.toString();
            (this.props.customMessage.hash = data?.hash || ""),
                store.dispatch(setCustomMessage(this.props.customMessage));
            const service: ChatService = await ChatService.getInstance();
            service.sendCustomMessage();
        }
        this.props.navigation.navigate("Chat");
    };
}

const mapStateToProps = (state) => {
    return {
        currencies: state.wallet.currencies,
        selectedCurrency: state.wallet.selectedCurrency,
        fiatCurrency: state.wallet.fiatCurrency,
        currentOperation: state.wallet.currentOperation,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
        onSuccess: true,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmSendCryptoScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmSendCryptoScreen);

export default ConfirmSendCryptoScreen;
