import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { colors } from '@styles/globalStyles';
import { connect } from 'react-redux';
import Icon from '@components/icons';
import BoldText from '@base/BoldText';
import RegularText from '@base/RegularText';

interface State { }

interface Props {
    snackbar?: SnackbarOptions
}

export class _Snackbar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={(this.props.snackbar != null) ? styles.wrapper : styles.hidden}>
                <View style={styles.wrapper && styles.background}>
                    {this.props.snackbar && this.props.snackbar?.type == 'SUCCESS' &&
                        <Icon size={16} name={'check-circle'} color={colors.text} style={styles.icon}></Icon>
                    }
                    {this.props.snackbar && this.props.snackbar?.type == 'ERROR' &&
                        <Icon size={16} name={'x-circle'} color={colors.text} style={styles.icon}></Icon>
                    }
                    {this.props.snackbar && this.props.snackbar?.type == 'MESSAGE' &&
                        <Icon size={16} name={'info-circle'} color={colors.text} style={styles.icon}></Icon>
                    }
                    <RegularText fontSize={14} color={colors.text} style={{ flex: 1 }}>{this.props.snackbar?.message}</RegularText>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: 'none'
    },
    wrapper: {
        height: '10%',
        flex: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '10%',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        position: 'absolute',
        backgroundColor: colors.popupBackground,
        padding: 14,
        width: '80%',
        justifyContent: 'flex-start',
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 16,
        borderRadius: 8,
        maxWidth: 400,
    },
    icon: {
        fontSize: 20,
        marginRight: 12,
        zIndex: 999
    }
});


const mapStateToProps = (state) => {
    return state.global;
};

const mapDispatchToProps = (dispatch) => ({

});

const Snackbar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_Snackbar);

export default Snackbar;